// react
import { lazy, Suspense, useState } from 'react';
// react-router-dom
import { Routes, Route, BrowserRouter } from 'react-router-dom';

// App styles
import './App.css';

// components
import NavBar from './components/NavBar/index';
import Footer from './components/Footer/index';
import GoToTopBtn from './components/GoToTopBtn/index';
import Spinner from './components/Spinner';
import AppNotificationBar from './components/AppNotificationBar/index.js';

// pages
const Home = lazy(() => import('./pages/Home/index.js'));
const Projects = lazy(() => import('./pages/Projects/index.js'));
const Certificates = lazy(() => import('./pages/Certificates/index.js'));
const SingleCertificate = lazy(() =>
  import('./pages/SingleCertificate/index.js')
);
const Repos = lazy(() => import('./pages/Repos/index.js'));
const Uses = lazy(() => import('./pages/Uses/index.js'));
const Articles = lazy(() => import('./pages/Articles/index.js'));
const ArticleTags = lazy(() => import('./pages/ArticleTags/index.js'));
const ArticlePage = lazy(() => import('./pages/ArticlePage/index.js'));
const AboutMe = lazy(() => import('./pages/AboutMe/index'));
const Contact = lazy(() => import('./pages/Contact/index.js'));
const NotFound = lazy(() => import('./pages/NotFound/index.js'));

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Suspense fallback={<Spinner />}>
          {<AppNotificationBar />}

          <NavBar />

          <Routes>
            <Route index element={<Home />} />
            <Route path="/articles" element={<Articles />} />
            <Route index element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/certificates" element={<Certificates />} />
            <Route path="/certificates/:id" element={<SingleCertificate />} />
            <Route path="/repos" element={<Repos />} />
            <Route path="/uses" element={<Uses />} />
            <Route path="/articles/:id" element={<ArticlePage />} />
            <Route path="/tags/:id" element={<ArticleTags />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <span className="goToTopBtn">
            <GoToTopBtn />
          </span>

          <Footer />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
