// react
import React, { useState, useEffect, memo } from 'react';
// react-redux
import { useDispatch } from 'react-redux';
// react-router-dom
import { NavLink, Link } from 'react-router-dom';

// components
import HireMeModal from '../HireMeModal';
import DoActionBtn from '../DoActionBtn/index';

// function Creators
import { ColorMode } from '../../redux/Reducers/VercelReducer/actions';

// styles, icons
import styles from './styles.module.css';
import { BsSun, BsMic } from 'react-icons/bs';
import { HiOutlineMoon } from 'react-icons/hi';
import { CgMenuRightAlt } from 'react-icons/cg';
import { MdOutlineCloseFullscreen, MdOutlineArticle } from 'react-icons/md';
import { FiUser } from 'react-icons/fi';
import { IoIosGitNetwork } from 'react-icons/io';
import { AiOutlineSetting, AiOutlineOrderedList } from 'react-icons/ai';

const LIGHT_MODE = {
  text: '#1a1a1a',
  'text-sub-color-1': '#3F3F46',
  'text-sub-color-2': '#A3A3A3',

  'background-color': '#fff',
  'background-sub-color-1': '#f2f2f2',
  'background-sub-color-2': '#E4E4E7',
  'background-sub-color-3': '#d9d9d9',

  'border-color': '#f2f2f2',
  'border-sub-color-1': '#E4E4E7',
  'border-sub-color-2': '#d9d9d9',
};

const DARK_MODE = {
  text: '#fff',
  'text-sub-color-1': '#f2f2f2',
  'text-sub-color-2': '#d9d9d9',

  'background-color': '#1a1a1a',
  'background-sub-color-1': '#27272A',
  'background-sub-color-2': '#3F3F46',
  'background-sub-color-3': '#A3A3A3',

  'border-color': '#27272A',
  'border-sub-color-1': '#3F3F46',
  'border-sub-color-2': '#A3A3A3',
};

function NavBar() {
  const dispatch = useDispatch();

  const getStoredTheme = localStorage.getItem('theme');
  const [colorMode, setColorMode] = useState('' || getStoredTheme);

  const [showModal, setShowModal] = useState(false);
  const [userMenuBtnClicked, setUserMenuBtnClicked] = useState(false);

  // to render dark-light mode by clicking
  function toggleColorMode() {
    setColorMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));

    const root = document.documentElement;

    if (colorMode === 'dark') {
      Object.keys(DARK_MODE).forEach((key) => {
        root.style.setProperty(`--${key}`, DARK_MODE[key]);
      });
    } else {
      Object.keys(LIGHT_MODE).forEach((key) => {
        root.style.setProperty(`--${key}`, LIGHT_MODE[key]);
      });
    }
  }

  // to render dark mode directly on the site
  useEffect(() => {
    if (colorMode === 'dark') {
      // text colors
      document.documentElement.style.setProperty(
        '--text-color',
        DARK_MODE.text
      );
      document.documentElement.style.setProperty(
        '--text-sub-color-1',
        DARK_MODE['text-sub-color-1']
      );
      document.documentElement.style.setProperty(
        '--text-sub-color-2',
        DARK_MODE['text-sub-color-2']
      );

      // background colors
      document.documentElement.style.setProperty(
        '--background-color',
        DARK_MODE['background-color']
      );
      document.documentElement.style.setProperty(
        '--background-sub-color-1',
        DARK_MODE['background-sub-color-1']
      );
      document.documentElement.style.setProperty(
        '--background-sub-color-2',
        DARK_MODE['background-sub-color-2']
      );

      // border colors
      document.documentElement.style.setProperty(
        '--border-color',
        DARK_MODE['border-color']
      );
      document.documentElement.style.setProperty(
        '--border-sub-color-1',
        DARK_MODE['border-sub-color-1']
      );
      document.documentElement.style.setProperty(
        '--border-sub-color-2',
        DARK_MODE['border-sub-color-2']
      );
    } else {
      // text colors
      document.documentElement.style.setProperty(
        '--text-color',
        LIGHT_MODE.text
      );
      document.documentElement.style.setProperty(
        '--text-sub-color-1',
        LIGHT_MODE['text-sub-color-1']
      );
      document.documentElement.style.setProperty(
        '--text-sub-color-2',
        LIGHT_MODE['text-sub-color-2']
      );

      // background colors
      document.documentElement.style.setProperty(
        '--background-color',
        LIGHT_MODE['background-color']
      );
      document.documentElement.style.setProperty(
        '--background-sub-color-1',
        LIGHT_MODE['background-sub-color-1']
      );
      document.documentElement.style.setProperty(
        '--background-sub-color-2',
        LIGHT_MODE['background-sub-color-2']
      );

      // border colors
      document.documentElement.style.setProperty(
        '--border-color',
        LIGHT_MODE['border-color']
      );
      document.documentElement.style.setProperty(
        '--border-sub-color-1',
        LIGHT_MODE['border-sub-color-1']
      );
      document.documentElement.style.setProperty(
        '--border-sub-color-2',
        LIGHT_MODE['border-sub-color-2']
      );
    }

    // send color mode
    dispatch(ColorMode(colorMode));
    localStorage.setItem('theme', colorMode || 'light');
  }, [colorMode]);

  // close userMenu if the screen width more than 70em
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 70em)');
    const handleResize = (e) => {
      if (e.matches) {
        setUserMenuBtnClicked(false);
      }
    };
    mediaQuery.addListener(handleResize);
    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, []);

  // For modal, closing & opining
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // close userMenu when user clicks on window
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       logOutBoxRef.current &&
  //       !logOutBoxRef.current.contains(event.target)
  //     ) {
  //       setUserMenuBtnClicked(false);
  //     }
  //   }
  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [logOutBoxRef]);

  return (
    <>
      <div className={styles.navBarBody}>
        {/* Form modal */}
        <HireMeModal
          colorMode={colorMode}
          show={showModal}
          handleClose={handleCloseModal}
        />

        {/* Logo container */}
        <div className={styles.logoCont}>
          <Link to="/">
            <span>H</span>
          </Link>
        </div>

        {/* Links container */}
        <div className={styles.linksCont}>
          <NavLink className={styles.navLink} to="/about">
            About
          </NavLink>

          <NavLink className={styles.navLink} to="/articles">
            Articles
          </NavLink>

          <NavLink className={styles.navLink} to="/contact">
            Contact
          </NavLink>

          <NavLink className={styles.navLink} to="/projects">
            Projects
          </NavLink>

          <NavLink className={styles.navLink} to="/repos">
            Repos
          </NavLink>

          <NavLink className={styles.navLink} to="/uses">
            Uses
          </NavLink>
        </div>

        {/* Do action container */}
        <div className={styles.doActionCont}>
          <span>
            <DoActionBtn
              btnOnClickFun={handleShowModal}
              BtnTextContent={'Hire Me'}
            />
          </span>

          <span
            className={styles.toggleColorModeCont}
            onClick={toggleColorMode}
          >
            {colorMode === 'dark' ? (
              <BsSun className={styles.toggleColorModeIconLight} />
            ) : (
              <HiOutlineMoon className={styles.toggleColorModeIconDark} />
            )}
          </span>

          {/* Menu icon container */}
          <span className={styles.menuIconCont}>
            {/* Open userMenu icon  */}
            {!userMenuBtnClicked && (
              <CgMenuRightAlt
                onClick={() => {
                  setUserMenuBtnClicked(!userMenuBtnClicked);
                }}
                className={styles.menuIcon}
              />
            )}

            {/* Close userMenu icon */}
            {userMenuBtnClicked && (
              <MdOutlineCloseFullscreen
                onClick={() => {
                  setUserMenuBtnClicked(!userMenuBtnClicked);
                }}
                className={styles.menuIcon}
              />
            )}
          </span>
        </div>
      </div>

      {/* userMenu panel */}
      {userMenuBtnClicked && (
        <div className={styles.menuContent}>
          <div className={styles.menuContentCont}>
            <span>
              <NavLink className={styles.navLink} to="/about">
                <FiUser className={styles.menuNavIcon} />
                About
              </NavLink>
            </span>

            <span>
              <NavLink className={styles.navLink} to="/articles">
                <MdOutlineArticle className={styles.menuNavIcon} />
                Articles
              </NavLink>
            </span>

            <span>
              <NavLink className={styles.navLink} to="/contact">
                <BsMic className={styles.menuNavIcon} />
                Contact
              </NavLink>
            </span>

            <span>
              <NavLink className={styles.navLink} to="/projects">
                <AiOutlineOrderedList className={styles.menuNavIcon} />
                Projects
              </NavLink>
            </span>

            <span>
              <NavLink className={styles.navLink} to="/repos">
                <IoIosGitNetwork className={styles.menuNavIcon} />
                Repos
              </NavLink>
            </span>

            <span>
              <NavLink className={styles.navLink} to="/uses">
                <AiOutlineSetting className={styles.menuNavIcon} />
                Uses
              </NavLink>
            </span>

            {/* HireMe btn */}
            <span>
              <DoActionBtn
                BtnTextContent={'Hire Me'}
                btnOnClickFun={handleShowModal}
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default memo(NavBar);
