// react
import React, { memo } from "react";
// react-redux
import { useSelector } from "react-redux";

// react-spinners
import BarLoader from "react-spinners/BarLoader";

// styles
import styles from "./styles.module.css";

function Spinner() {
  const { colorMode } = useSelector((state) => state.VercelReducer);
  const getStoredColorMode = localStorage.getItem("theme") || colorMode;

  return (
    <div
      className={styles.spinnerContainer}
      style={{
        backgroundColor: getStoredColorMode === "dark" ? "#1a1a1a" : "#fff",
      }}
    >
      <BarLoader
        color={`${getStoredColorMode === "dark" ? "#ffffff" : "#1a1a1a"}`}
        className={styles.spinner}
      />
    </div>
  );
}

export default memo(Spinner);
