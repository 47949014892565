// react
import React, { useState, memo } from 'react';

// library
import emailjs from 'emailjs-com';

// styles & icon
import styles from './styles.module.css';
import { IoIosClose } from 'react-icons/io';

function HireMeModal({ handleClose, show, colorMode }) {
  // EmailJs keys
  const emailjsAPIKey = '6KCLKxySDioDpCCWs';
  const emailjsServiceID = 'service_elsmjqa';
  const emailjsTemplateID = 'template_2milqto';

  const [buttonText, setButtonText] = useState('Send Request');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    projectType: '',
    description: '',
  });
  const [formErrors, setFormErrors] = useState({
    nameError: '',
    emailError: '',
    projectTypeError: '',
    descriptionError: '',
  });

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setButtonText('Sending . . .');

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Form validations
    const errors = {
      nameError: formData.name.length < 6 ? 'Provide a regular full name!' : '',
      emailError: !emailPattern.test(formData.email)
        ? 'Invalid email address!'
        : '',
      projectTypeError:
        formData.projectType === '' ? 'Select a project type!' : '',
      descriptionError:
        formData.description.length < 60
          ? 'A description should be clear and enough detailed!'
          : '',
    };

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error !== '')) {
      setButtonText('Send Request');
      return;
    }

    emailjs.init(emailjsAPIKey);
    emailjs
      .send(emailjsServiceID, emailjsTemplateID, {
        to_name: 'Huthaifa Altiti',
        from_name: formData.name,
        from_email: formData.email,
        subject: formData.projectType,
        message: formData.description,
      })
      .then((res) => {
        // console.log('Email sent successfully: ', res);
        setButtonText('Message sent successfully ✔');
      })
      .catch((err) => {
        // console.error('Email send error:', err);
        setButtonText('Send Request');
      });
  };

  // prevent scrolling when modal is opened
  if (show === true) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
  }

  return (
    <div className={`${show ? styles.modal : styles.hideModal}`}>
      <section className={styles.modalMain}>
        <header>
          <h3>What project are you looking for?</h3>
          <span
            className={`${
              colorMode === 'dark'
                ? styles.closeIconCont
                : styles.closeIconContLight
            }`}
            onClick={handleClose}
          >
            <IoIosClose className={styles.closeIcon} />
          </span>
        </header>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleFieldChange}
            placeholder="Name"
            required
            className={`${
              /[\u0600-\u06FF]/.test(formData.name) ? styles.rtl : ''
            }`}
          />
          {formErrors.nameError && (
            <p className={styles.error}>{formErrors.nameError}</p>
          )}

          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleFieldChange}
            placeholder="Email"
            required
          />
          {formErrors.emailError && (
            <p className={styles.error}>{formErrors.emailError}</p>
          )}

          <select
            name="projectType"
            value={formData.projectType}
            onChange={handleFieldChange}
            required
          >
            <option value="" hidden>
              Select a project type
            </option>
            <option value="Web Application">Web Application</option>
            <option value="UI/UX Design">UI/UX Design</option>
            <option value="Branding">Branding</option>
          </select>
          {formErrors.projectTypeError && (
            <p className={styles.error}>{formErrors.projectTypeError}</p>
          )}

          <textarea
            name="description"
            value={formData.description}
            onChange={handleFieldChange}
            placeholder="Description"
            required
            className={`${
              /[\u0600-\u06FF]/.test(formData.description) ? styles.rtl : ''
            }`}
          />
          {formErrors.descriptionError && (
            <p className={styles.error}>{formErrors.descriptionError}</p>
          )}

          <button
            type="submit"
            style={{
              pointerEvents: buttonText !== 'Send Request' ? 'none' : 'auto',
              cursor: buttonText !== 'Send Request' ? 'not-allowed' : 'auto',
            }}
          >
            {buttonText}
          </button>
        </form>
      </section>
    </div>
  );
}

export default memo(HireMeModal);
