// react
import React, { memo } from "react";

// styles
import styles from "./styles.module.css";

function DoActionBtn({ BtnTextContent, btnOnClickFun }) {
  return (
    <div className={styles.DoActionBtnBody}>
      <button onClick={btnOnClickFun}>{BtnTextContent}</button>
    </div>
  );
}

export default memo(DoActionBtn);
