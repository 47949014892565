// React
import React, { useState, useLayoutEffect, memo } from 'react';

// Contentful APIs
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// styles, icons
import styles from './styles.module.css';

const AppSingleNotification = ({ notification }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const renderNodesOptions = {
    renderMark: {
      [MARKS.BOLD]: (text) => (
        <span className={styles.boldTextContentful}>{text}</span>
      ),
      [MARKS.ITALIC]: (text) => (
        <span className={styles.italicTextContentful}>{text}</span>
      ),
      [MARKS.UNDERLINE]: (text) => (
        <span className={styles.underLineTextContentful}>{text}</span>
      ),
    },
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className={styles.uListContentful}>{children}</ul>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className={styles.paraTextContentful}>{children}</p>
      ),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        if (node.data.target.sys.contentType.sys.id === 'blogPost') {
          return (
            <a href={`/blog/${node.data.target.fields.slug}`}>
              {node.data.target.fields.title}
            </a>
          );
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <img
            src={`https://${node.data.target.fields.file.url}`}
            alt={node.data.target.fields.description}
          />
        );
      },
    },
  };

  // edit padding of the notification depends on screen width
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const notificationColor = `#${notification.fields.notificationBackgroundColor}`;
  let paddingValue = '5px 50px';

  if (screenWidth > 2000) {
    paddingValue = '5px 500px';
  } else if (screenWidth < 500) {
    paddingValue = '5px 10px';
  }

  const notificationStyle = {
    backgroundColor: notificationColor,
    padding: paddingValue,
  };

  return (
    isVisible && (
      <div style={notificationStyle} className={styles.msgContainer}>
        <p>
          {documentToReactComponents(
            notification.fields.notificationMessageContent,
            renderNodesOptions
          )}
        </p>
        <button onClick={() => setIsVisible(false)}>X</button>
      </div>
    )
  );
};

export default memo(AppSingleNotification);
