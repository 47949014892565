// React
import React, { useEffect, memo } from 'react';
// react-redux
import { useDispatch, useSelector } from 'react-redux';

// action creators
import { fetchContentfulProjects } from '../../redux/Reducers/ContentfulReducer/actions';

// Component
import AppSingleNotification from '../AppSingleNotification';

const AppNotificationBar = () => {
  const dispatch = useDispatch();

  // Contentful
  const { ContentfulProjects } = useSelector(
    (state) => state.ContentfulReducer
  );

  const notifications = ContentfulProjects?.portfolioHomeNotificationBarEntry;

  useEffect(() => {
    !ContentfulProjects.length && dispatch(fetchContentfulProjects());
  }, [dispatch]);

  return (
    <>
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification) => (
          <AppSingleNotification
            key={notification?.fields?.notificationId}
            notification={notification}
          />
        ))}
    </>
  );
};

export default memo(AppNotificationBar);
